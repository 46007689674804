import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.less']
})
export class TableHeaderComponent {
  @Input() itensNumber: number = 0;
  @Input() totalNumber: number = 0;
  @Input() hasCSV: boolean = false;
  @Input() hasPDF: boolean = false;
  @Input() loadingData$: any;
  @Output() downloadCSV = new EventEmitter<boolean>();
  @Output() downloadPDF = new EventEmitter<boolean>();

  constructor() { }

  exportCSV() {
    this.downloadCSV.emit(true);
  }

  exportPDF() {
    this.downloadPDF.emit(true);
  }
}
