import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-helper-modal',
  templateUrl: './helper-modal.component.html',
  styleUrls: ['./helper-modal.component.less']
})

export class HelperModalComponent {

  constructor(private router: Router) {
    // Para obter a rota atual, você pode usar o evento 'NavigationEnd' do Router.
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPage = event.url;
      }
    });

    /** Verifica se a empresa é iluminaSP (pois alguns relatórios só existem para IluminaSP) **/
    if (this.company == 'Q29tcGFueToxNg==') {
      /** Exibe as informações sobre os relatórios de IluminaSP**/
      this.isReportIluminaSP = true;
    }
  }

  /** Armazena a empresa atual selecionado pelo usuário **/
  public company = localStorage.getItem('lastCompanySelected');

  public currentPage: string = this.router.url;

  /** Propriedade utilizada para manipular quando algumas informações do relatório 
   * deverão ou não ser exibidas no menu de ajuda 
   * 
   * **/
  public isReportIluminaSP: boolean = false;

  /** Armazena o caminho dos gifs de demonstração **/
  public gifUrl: string | null

  /** Método que manipula quando o modo demonstração deverá ser exibido, de acordo com o caminho de arquivo especifico  **/
  public openDemo(url: string) {
    this.gifUrl = url;
  }

  /** Fecha o gif de demonstração limpando a url **/
  public closeDemo() {
    this.gifUrl = null;
  }
}
