import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { from, mergeMap, catchError, of, tap, Observable, take } from 'rxjs';
import { ImportsCSVFileService } from 'src/shared/services/imports-CSV.service';
import { Site } from '../../assets-imports-csv/imports-csv-installations-model';

@Component({
  selector: 'app-import-csv-update-installation-sites',
  templateUrl: './import-csv-update-installation-sites.component.html',
  styleUrl: './import-csv-update-installation-sites.component.less'
})
export class ImportCsvUpdateInstallationSitesComponent {

  constructor(
    private importsCSVFileService: ImportsCSVFileService,
    private dialog: MatDialog
  ) {
    this.company = localStorage.getItem('lastCompanySelected') ? localStorage.getItem('lastCompanySelected') : null;
  }

  /** Utilizado para acessar o campo de input dos arquivos **/
  @ViewChild('fileUploadSimple') fileUploadSimple: ElementRef;

  /** Utilizado para acessar o stepper de "duvidas" no html **/
  @ViewChild('stepper') private stepper: MatStepper;

  /** Utilizado para acessar o elemento do modal do passo a passo **/
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;


  /** Armazena a empresa selecionada pelo usuário **/
  public company: string | null;

  /** Armazena o conteúdo do arquivo **/
  private data: string;

  /** Array que armazena os dados do arquivo CSV **/
  private dataToImport: Array<any> = [];

  public logReadyMessage: boolean = false;

  /** Utilizado para manipular o botão de download dos logs **/
  public isExported: boolean = false;

  /** Lista para armazenar os logs **/
  private log: Array<string> = new Array<string>();

  /** Propriedade utilizada para manipular as linhas do arquivo  **/
  public actualLine: number = 0;

  /** Loading de update **/
  public loadingUpdate: boolean = false;

  /** Utilizado para manipular a inativação do botão de importação **/
  public disableImportButton: boolean;

  /** Método utilizado para redirecionar o usuário para um stepper especifico definindo o seu index no html **/
  public goToStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  /** Abre o modal que exibe o passo a passo para a importação **/
  public openDialog(): void {
    this.dialog.open(this.dialogTemplate, {
      data: {}
    });
  }

  /** Método que lê o arquivo CSV **/
  public async getTextFromFile(event: any) {
    const file: File = event.target.files[0];
    let fileContent = await file.text();

    this.data = fileContent;

    /** Reseta todas as mensagens exibidas após o envio de algum arquivo **/
    this.logReadyMessage = false;

    /** Desabilita o botão de exportar logs **/
    this.isExported = false;

    /** Limpa a lista de logs sempre que um novo arquivo é selecionado **/
    this.log = [];

    /** Habilita o botão de importação **/
    this.disableImportButton = false;
  }

  /** Método que realiza a leitura e importação dos arquivos CSV **/
  public async importDataFromCSV() {
    this.dataToImport = this.importsCSVFileService.importDataFromCSV(this.data);

    const dataMappings = this.dataToImport.map(row => ({
      reference: row.reference,
      latitude: row.latitude,
      longitude: row.longitude,
      country: row.country,
      district: row.district,
      state: row.state,
      city: row.city,
      street: row.street,
      number: row.number,
      details: row.details,
      locationCode: row.locationCode,
    }));

    /** Inicia o processo de importação do arquivo utilizando os métodos do rxjs **/
    from(dataMappings).pipe(
      /** Processa as instalações e todos os campos, limitando as solicitações em 5 **/
      mergeMap(({
        reference,
        latitude,
        longitude,
        country,
        district,
        state,
        city,
        street,
        number,
        details,
        locationCode,
      }) => this.processSiteAndUpdate(
        reference,
        latitude,
        longitude,
        country,
        district,
        state,
        city,
        street,
        number,
        details,
        locationCode
      ), 5),
      /** Caso ocorra algum erro **/
      catchError(err => {
        /** Adiciona o erro na lista de logs **/
        this.log.push('Erro no processamento geral: ' + err);

        console.log('Erro no processamento geral', err)

        /** Ativa o botão de exportar logs **/
        this.isExported = true;
        /** Desativa o loading **/
        this.loadingUpdate = false;
        /** Botão é ativado **/
        this.disableImportButton = false;
        /** Exibe mensagem de confirmação ao usuário **/
        this.logReadyMessage = true;

        return of(null); // Continua a execução mesmo com erro
      }),

      /** Manipulação do arquivo após cada linha do arquivo ser processada **/
      tap(() => {

        /** Acrescenta +1 a linha atual **/
        this.actualLine += 1;

        if (this.actualLine === dataMappings.length) {
          /** Após 6 segundos **/
          setTimeout(() => {
            /** Ativa o botão de exportar logs **/
            this.isExported = true;
            /** Desativa o loading **/
            this.loadingUpdate = false;
            /** Botão é ativado **/
            this.disableImportButton = true;
            /** Exibe mensagem de confirmação ao usuário **/
            this.logReadyMessage = true;
            /** Reseta o contador das linhas **/
            this.actualLine = 0;
            /** Limpa o input dos arquivos que contém o arquivo anexado 
             já que o método que lê o arquivo não limpa o input caso o nome do arquivo seja igual) **/
            this.fileUploadSimple.nativeElement.value = null;
          }, 6000);
        }
      })
    ).subscribe();
  }

  /** Processa todos os dados e realiza o update dos locais retornando um observable **/
  private processSiteAndUpdate(
    siteReference: string,
    latitude: string | null,
    longitude: string | null,
    country: string | null,
    district: string | null,
    state: string | null,
    city: string | null,
    street: string | null,
    number: string | null,
    details: string | null,
    locationCode: string | null,
  ): Observable<any> {

    /** Ativa o loading na tela **/
    this.loadingUpdate = true;
    /** Retira a mensagem de confirmação dos logs ao usuário **/
    this.logReadyMessage = false;
    /** Desativa o botão de importação **/
    this.disableImportButton = true;

    /** Validação para caso o campo de referência não esteja preenchido**/
    if (!siteReference) {
      this.log.push(`Campo de referência inválido!`);
      return of(null);
    }

    /** Variável que armazena as informações dos locais  **/
    let installationSite: Site;

    /** Realiza o filtro de locais pela referência **/
    return this.importsCSVFileService.getInstallationSiteByReference(siteReference).valueChanges
      .pipe(
        take(1),
        mergeMap((res: any) => {

          /** Filtrando os locais pela referencia obtida no arquivo **/
          const installationSites: any[] = res.data.installationSite.edges.map((edge: any) => edge.node)
            .filter((site: Site) => site.reference === siteReference)

          /** Caso não seja encontrado nenhum local no filtro **/
          if (installationSites.length === 0) {

            /** Adiciona mensagem de falha na lista de logs **/
            this.log.push(`Local de instalação ${siteReference} não encontrado!`);
            /** Encerrando esse processo nas referencias **/
            return of(null);
          }

          /** Armazena os dados do local correto **/
          installationSite = installationSites[0];
          installationSite.latitude = installationSites[0].geoposition.latitude
          installationSite.longitude = installationSites[0].geoposition.longitude

          /** Verifica caso haja campos vazios, para usar os valores antigos da API **/
          if (!latitude) latitude = installationSite.latitude;
          if (!longitude) longitude = installationSite.longitude;
          if (!country) country = installationSite.country;
          if (!district) district = installationSite.district;
          if (!state) state = installationSite.state;
          if (!city) city = installationSite.city;
          if (!street) street = installationSite.street;
          if (!number) number = installationSite.number;
          if (!details) details = installationSite.details;
          if (!locationCode) locationCode = installationSite.locationCode

          /** Realiza a atualização dos locais **/
          return this.importsCSVFileService.updateSite(
            installationSite.id,
            latitude,
            longitude,
            installationSite.reference,
            country,
            district,
            state,
            city,
            street,
            number,
            details,
            locationCode,
            installationSite.isActive
          ).pipe(
            tap(() => {
              /** Adiciona mensagem de sucesso na lista de logs. **/
              this.log.push(`Local ${siteReference} atualizado com sucesso!`);
            }),
            catchError(err => {
              /** Exibe o erro no console **/
              console.error(`Erro ao atualizar o local: ${siteReference}`, err);

              /** Adiciona o erro na lista de logs **/
              this.log.push(`Erro ao atualizar o local: ${siteReference} (${err})`);

              /** Continua o processo mesmo com erro **/
              return of(null);
            })
          )
        })
      )
  }

  /** Método utilizado para exportar os Logs de sucesso ou falha da importação dos arquivos **/
  public exportLogs() {
    const logText = this.log.join("\n");
    const blob = new Blob([logText], { type: 'text/plain' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${new Date().toLocaleDateString()}-${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}_Update_Installation-Site.log`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  /** Método utilizado para gerar o conteúdo do CSV a partir dos cabeçalhos **/
  private generateCsvContent(headers: string[]): string {
    return headers.join(';') + '\n'; // Agrupa os cabeçalhos separando por ponto e vírgula e adiciona uma nova linha no final
  }

  /** Método que realiza o download de um arquivo csv que contém o cabeçalho com o modelo para os usuários **/
  public downloadCsvFile(): void {
    const headers = ['reference', 'latitude', 'longitude', 'country', 'district', 'state', 'city', 'street', 'number', 'details', 'locationCode']; //Define o cabeçalho do arquivo csv
    const fileName = 'model-import-update-installation-sites.csv'; //Nome do arquivo
    const csvContent = this.generateCsvContent(headers); // Gera o conteúdo do CSV com base nos cabeçalhos
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }); // Cria um objeto Blob com o conteúdo do CSV e define o tipo MIME
    const link = document.createElement('a');  // Cria um elemento <a> dinamicamente
    const url = URL.createObjectURL(blob);  // Cria uma URL para o Blob
    link.setAttribute('href', url);  // Define o atributo href do link com a URL do Blob
    link.setAttribute('download', fileName); // Define o atributo download do link com o nome do arquivo
    link.style.visibility = 'hidden'; // Define a visibilidade do link como 'hidden' para que ele não apareça na página
    document.body.appendChild(link); // Adiciona o link ao corpo do documento
    link.click(); // Simula um clique no link para iniciar o download do arquivo
    document.body.removeChild(link); // Remove o link do documento após o clique
  }
}
