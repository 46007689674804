import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/shared/authguard/auth-guard.guard';
import { AuditGuard } from 'src/shared/authguard/audit.guard';
import { SidebarComponent } from 'src/shared/components/sidebar/pages/sidebar.component';
import { UserComponent } from 'src/shared/components/userprofile/user/pages/user.component';
import { ReportComponent } from 'src/shared/components/report/pages/report.component';
import { CommandsComponent } from 'src/shared/components/commands/commands.component';
import { ReportChartsComponent } from 'src/shared/components/report-charts/pages/report-charts.component';
import { ManageUserComponent } from 'src/shared/components/manage-user/pages/manage-user.component';
import { ReportAuditComponent } from 'src/shared/components/report-audit/pages/report-audit.component';
import { CompaniesComponent } from 'src/shared/components/register/pages/companies/companies.component';
import { ServiceOrderComponent } from 'src/shared/components/service-order/service-order.component';
import { NotFoundComponent } from 'src/shared/components/not-found/not-found.component';
import { AuditEditComponent } from 'src/shared/components/report-audit/audit-edit/pages/audit-edit.component';
import { AlertRulesComponent } from 'src/shared/components/alert-rules/pages/alert-rules.component';
import { UnitMeasurementComponent } from 'src/shared/components/register/pages/unit-measurement/unit-measurement.component';
import { PropertiesComponent } from 'src/shared/components/register/pages/properties/properties.component';
import { InstallationSiteComponent } from 'src/shared/components/register/pages/installation-site/installation-site.component';
import { FirmwaresComponent } from 'src/shared/components/register/pages/firmwares/firmwares.component';
import { EquipmentsComponent } from 'src/shared/components/register/pages/equipments/equipments.component';
import { EquipmentTypeComponent } from 'src/shared/components/register/pages/equipment-type/equipment-type.component';
import { HardwaresComponent } from 'src/shared/components/register/pages/hardwares/hardwares.component';
import { ParametersComponent } from 'src/shared/components/register/pages/parameters/parameters.component';
import { MapComponent } from 'src/shared/components/map/pages/map.component';
import { ComponentsComponent } from 'src/shared/components/register/pages/components/components.component';
import { GatewaysComponent } from 'src/shared/components/register/pages/gateways/gateways.component';
import { AlertsComponent } from 'src/shared/components/alerts/pages/alerts.component';
import { MetersComponent } from 'src/shared/components/register/pages/meters/meters.component';
import { LampsTypeComponent } from 'src/shared/components/register/pages/lamps-type/lamps-type.component';
import { InstallationsComponent } from 'src/shared/components/register/pages/installations/installations.component';
import { DivisionComponent } from 'src/shared/components/register/pages/division/division.component';
import { LogsComponent } from 'src/shared/components/logs/logs.component';
import { InstallationLogComponent } from 'src/shared/components/logs/pages/installation-log/installation-log.component';
import { InstallationSiteLogComponent } from 'src/shared/components/logs/pages/installation-site-log/installation-site-log.component';
import { EquipmentLogComponent } from 'src/shared/components/logs/pages/equipment-log/equipment-log.component';
import { UserLogComponent } from 'src/shared/components/logs/pages/user-log/user-log.component';
import { RegisterComponent } from 'src/shared/components/register/register.component';
import { LoginComponent } from 'src/shared/components/login/pages/login.component';
import { CircuitBoxComponent } from 'src/shared/components/register/pages/circuit-box/circuit-box.component';
import { ImportsCSVComponent } from 'src/shared/components/imports-csv/imports-csv.component';
import { ImportsCsvInstallationsComponent } from 'src/shared/components/imports-csv/pages/imports-installations/imports-csv-equipment-installation/imports-csv-equipment-in-installations.component';
import { ImportsCsvUpdateInstallationsComponent } from 'src/shared/components/imports-csv/pages/imports-installations/imports-csv-update-installations/imports-csv-update-installations/imports-csv-update-installations.component';
import { ImportsCsvCreateEquipmentsComponent } from 'src/shared/components/imports-csv/pages/imports-equipments/imports-csv-create-equipments/imports-csv-create-equipments/imports-csv-create-equipments.component';
import { ImportsCsvCreateInstallationsComponent } from 'src/shared/components/imports-csv/pages/imports-installations/imports-csv-create-installations/imports-csv-create-installations/imports-csv-create-installations.component';
import { PermissionsGroupComponent } from 'src/shared/components/permissions-group/permissions-group.component';
import { ImportsCsvUpdateEquipmentComponent } from 'src/shared/components/imports-csv/pages/imports-equipments/imports-csv-update-equipments/imports-csv-update-equipment/imports-csv-update-equipment.component';
import { ImportsCsvInactivateInstallationsComponent } from 'src/shared/components/imports-csv/pages/imports-installations/imports-csv-inactivate-installations/imports-csv-inactivate-installations/imports-csv-inactivate-installations.component';
import { ImportsCsvDisassociateEquipmentInInstallationComponent } from 'src/shared/components/imports-csv/pages/imports-installations/imports-csv-disassociate-equipment/imports-csv-disassociate-equipment-in-installation/imports-csv-disassociate-equipment-in-installation.component';
import { ImportCsvUpdateInstallationSitesComponent } from 'src/shared/components/imports-csv/pages/imports-installation-sites/import-csv-update-installation-sites/import-csv-update-installation-sites.component';

const routes: Routes = [
  { path: '', component: MapComponent, canActivate: [AuthGuard] },
  { path: 'map', component: MapComponent, canActivate: [AuthGuard] },
  { path: 'sidebar', component: SidebarComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'commands', component: CommandsComponent, canActivate: [AuthGuard] },
  { path: 'reportcharts', component: ReportChartsComponent, canActivate: [AuthGuard] },
  { path: 'manageuser', component: ManageUserComponent, canActivate: [AuthGuard] },
  { path: 'permissionsGroup', component: PermissionsGroupComponent, canActivate: [AuthGuard] },
  { path: 'audit', component: ReportAuditComponent, canActivate: [AuthGuard, AuditGuard] },
  { path: 'audit/auditEdit/:id', component: AuditEditComponent, canActivate: [AuthGuard, AuditGuard] },
  { path: 'serviceorder', component: ServiceOrderComponent, canActivate: [AuthGuard] },
  { path: 'alertrules', component: AlertRulesComponent, canActivate: [AuthGuard] },
  { path: 'alerts', component: AlertsComponent, canActivate: [AuthGuard] },
  { path: 'logs', component: LogsComponent, canActivate: [AuthGuard] },
  { path: 'importsCSV', component: ImportsCSVComponent, canActivate: [AuthGuard] },
  { path: 'importsCSV/installationsImport/equipment', component: ImportsCsvInstallationsComponent, canActivate: [AuthGuard] },
  { path: 'importsCSV/installationsImport/installations-update', component: ImportsCsvUpdateInstallationsComponent, canActivate: [AuthGuard] },
  { path: 'importsCSV/installationsImport/installations-inactivate', component: ImportsCsvInactivateInstallationsComponent, canActivate: [AuthGuard] },
  { path: 'importsCSV/equipmentsImport/equipments-create', component: ImportsCsvCreateEquipmentsComponent, canActivate: [AuthGuard] },
  { path: 'importsCSV/equipmentsImport/equipments-update', component: ImportsCsvUpdateEquipmentComponent, canActivate: [AuthGuard] },
  { path: 'importsCSV/installationsImport/installations-create', component: ImportsCsvCreateInstallationsComponent, canActivate: [AuthGuard] },
  { path: 'importsCSV/installationsImport/disassociate-equipment', component: ImportsCsvDisassociateEquipmentInInstallationComponent, canActivate: [AuthGuard] },
  { path: 'importsCSV/installationSiteImport/installation-sites-update', component: ImportCsvUpdateInstallationSitesComponent, canActivate: [AuthGuard] },
  { path: 'logs/installation', component: InstallationLogComponent, canActivate: [AuthGuard] },
  { path: 'logs/installation_site', component: InstallationSiteLogComponent, canActivate: [AuthGuard] },
  { path: 'logs/equipment', component: EquipmentLogComponent, canActivate: [AuthGuard] },
  { path: 'logs/user', component: UserLogComponent, canActivate: [AuthGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'register/companies', component: CompaniesComponent, canActivate: [AuthGuard] },
  { path: 'register/unit-measurement', component: UnitMeasurementComponent, canActivate: [AuthGuard] },
  { path: 'register/installation-site', component: InstallationSiteComponent, canActivate: [AuthGuard] },
  { path: 'register/parameters', component: ParametersComponent, canActivate: [AuthGuard] },
  { path: 'register/properties', component: PropertiesComponent, canActivate: [AuthGuard] },
  { path: 'register/firmwares', component: FirmwaresComponent, canActivate: [AuthGuard] },
  { path: 'register/equipments', component: EquipmentsComponent, canActivate: [AuthGuard] },
  { path: 'register/equipment-type', component: EquipmentTypeComponent, canActivate: [AuthGuard] },
  { path: 'register/hardwares', component: HardwaresComponent, canActivate: [AuthGuard] },
  { path: 'register/gateways', component: GatewaysComponent, canActivate: [AuthGuard] },
  { path: 'register/components', component: ComponentsComponent, canActivate: [AuthGuard] },
  { path: 'register/meters', component: MetersComponent, canActivate: [AuthGuard] },
  { path: 'register/lampsType', component: LampsTypeComponent, canActivate: [AuthGuard] },
  { path: 'register/installations', component: InstallationsComponent, canActivate: [AuthGuard] },
  { path: 'register/division', component: DivisionComponent, canActivate: [AuthGuard] },
  { path: 'register/circuit-box', component: CircuitBoxComponent, canActivate: [AuthGuard] },
  { path: '404', component: NotFoundComponent, canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
